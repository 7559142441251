/* ----------------------------------------------------------------
	Custom CSS

	Add all your Custom Styled CSS here for New Styles or
	Overwriting Default Theme Styles for Better Hanlding Updates
-----------------------------------------------------------------*/

body {
  color: #555;
  font-family: 'Lato', sans-serif;
  letter-spacing: .02em;
}

p,
h1,
h2,
h3,
div,
ol,
li {
  color: #444;
  margin-bottom: 0;
}

a {
  color: #5f9741;
  font-weight: 600;
}

/* TOP MENU START */

.navbar {
  background: rgba(65, 70, 80, 1);
  height: 55px;
  display: block;
  padding: 0;
}

.navbar-brand {
  background-image: url("/images/logo-loyalade-wide.svg");
  height: 55px;
  width: 125px;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 15px;
}

#logo {
  float: left;
}

.btn-social {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

.facebook {
  background: url("/images/icon-facebook.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.instagram {
  background: url("/images/icon-instagram.svg");
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-social:active {
  background-color: #66a644;
  animation-delay: 0ms;
  animation-duration: 0ms;
  transition: background-color 200ms ease;
  transition-delay: 0;
}

/* TOP MENU END */

/* IMAGE HEADER START */

.parallax-banner {
  display: flex;
  align-items: center;
  height: 450px !important;
}

.parallax-banner h2 {
  font-size: 3rem;
  letter-spacing: 2px;
  text-transform: none;
  font-weight: 400;
  line-height: 120%;
  color: white;
}

@media (max-width: 767px) {
  .parallax-banner h2 {
    font-size: 2.5em;
  }
}

@media (max-width: 576px) {
  .parallax-banner h2 {
    font-size: 2em;
  }
}

.headerInfo {
  position: absolute;
  top: -27px;
  width: 70%;
}

/* IMAGE HEADER END */

::selection {
  background: #656567;
  color: #FFF;
  text-shadow: none;
}

.btnPressed:active,
.btnPressed:focus {
  filter: brightness(.5);
}

#content p {
  font-size: 1.35em;
  line-height: 1.5em;
  margin-bottom: 10px;
}

@media (max-width: 767px) and (min-width: 576px) {
  #content p {
    font-size: 1.2em;
  }
}

@media (max-width: 575px) {
  #content p {
    font-size: 1.1em;
    line-height: 1.3em;
  }
}

#content h2 {
  line-height: 1.3em;
  font-size: 2em;
}

.boldGreenHighlight {
  color: #66a744;
}

@media (max-width: 767px) and (min-width: 576px) {
  #content h2 {
    font-size: 1.6em;
  }
}

@media (max-width: 575px) {
  #content h2 {
    font-size: 1.2em;
    line-height: 1.35em;
  }
}

section#slider {
  display: flex;
  align-items: center;
  height: 100%;
  top: 55px;
}

.btnHeaderCTA {
  background: #66a744;
  color: white;
}

.btnHeaderCTA:hover {
  background: #66a744;
  color: white;
}

.animDelay500ms {
  animation-delay: 500ms;
}

.animDelay1s {
  animation-delay: 1000ms;
}

.animDelay2s {
  animation-delay: 2000ms;
}

.animDelay3s {
  animation-delay: 3000ms;
}

.modal-dialog {
  width: 100%;
  margin: 0 auto;
}

div#myModal {
  padding: 0 !important;
}

.modal-content {
  border: 0;
  background-color: #2b2b37;
  margin-top: 10vh;
}

@media (max-width: 767px) and (min-width: 576px) {
  .modal-content {
    margin-top: 8vh;
  }
}

@media (max-width: 575px) {
  .modal-content {
    margin-top: 0;
  }
  .modal-header {
    padding-top: 0;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  border: 0;
  padding-top: 0;
}

.modal-header {
  border: 0;
  padding: 20px;
  padding-bottom: 0;
  padding-top: 10px;
  align-items: center;
  margin: 0;
}

.formLogoContainer {
  width: 100%;
}

.formLogoContainer img {
  width: 200px;
}

@media (max-width: 575px) {
  .formLogoContainer img {
    width: 120px;
  }
}

.modal-header button.close {
  background-color: #67a744;
  opacity: 1;
  padding: 10px;
  width: 40px;
  height: 40px;
  line-height: 0px;
  color: white;
  border-radius: 3px;
  margin: 0;
}

.modal-body {
  padding: 0;
}

.modal-footer {
  margin: 0;
  padding: 10px;
}

body.dark,
.dark #wrapper,
.dark #content,
.dark .shop-quick-view-ajax,
.dark .portfolio-ajax-modal,
.dark .modal-content,
.dark .css3-spinner,
body.dark #side-panel,
body #side-panel.dark {
  background-color: #2b2b37;
}

h2.fadeInUp.animated {
  padding: 10px;
  padding-top: 20px;
  min-width: 100%;
  margin-bottom: 10px;
  animation-delay: 5s;
}

.headerLogo {
  width: 125px;
  display: none;
}

.followUs {
  color: white;
  margin-right: 10px;
}

.socialIconContainer {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 25px;
}

.social-icon.si-instagram,
.social-icon.si-facebook {
  color: #f8df60;
  cursor: pointer;
  font-style: normal;
  background: rgba(0, 0, 0, .3);
  border: 0;
}

h1>span:not(.nocolor):not(.badge),
h2>span:not(.nocolor):not(.badge),
h3>span:not(.nocolor):not(.badge),
h4>span:not(.nocolor):not(.badge),
h5>span:not(.nocolor):not(.badge),
h6>span:not(.nocolor):not(.badge) {
  color: unset;
}

ul.normalBullets {
  list-style: unset;
}

ul.normalBullets li {
  margin-left: 50px;
}

.sectionDivider h1 {
  font-size: 3em;
  font-weight: 400;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e4e5a+0,40454f+100 */
  background: #4e4e5a;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse farthest-corner, #4e4e5a 0%, #40454f 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, #4e4e5a 0%, #40454f 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #4e4e5a 0%, #40454f 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4e4e5a', endColorstr='#40454f', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  color: #f3d95e !important;
  margin: 0;
  padding: 30px;
  line-height: 1.25em
}

@media (max-width: 767px) and (min-width: 576px) {
  .sectionDivider h1 {
    font-size: 2.35em;
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .sectionDivider h1 {
    font-size: 1.75em;
    padding: 15px;
  }
}

.col_full.sectionDivider {
  font-size: 3em;
  font-weight: 400;
  background: #2e3237;
  margin: 0;
  padding: 30px;
  padding-top: 0;
}

.col_full label {
  color: #f3d95e;
  text-transform: none;
  font-weight: 400;
  font-size: .5em;
}

.g-recaptcha {
  display: flex;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.buttonContainer button {
  width: 40%;
  min-width: 300px;
}

.form-control.error,
.sm-form-control.error {
  border-width: 5px;
}

@media (max-width: 575px) {
  .swiper_wrapper .slider-caption h2 {
    font-size: 1.25em !important;
  }
  .swiper_wrapper:not(.force-full-screen),
  .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide {
    height: 300px !important;
  }
  .swiper_wrapper {
    height: 150px !important;
  }
}

.dark .slider-caption,
.dark .slider-caption h2 {
  color: #fff;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.8);
}

.iconAppreciation,
.iconEasy {
  max-width: 210px;
}

@media (max-width: 767px) and (min-width: 576px) {
  .iconAppreciation,
  .iconEasy {
    max-width: 250px;
  }
}

@media (max-width: 575px) {
  .iconAppreciation,
  .iconEasy {
    max-width: 180px;
  }
}

img.fadeInLeft.animated.iconAppreciation {
  animation-delay: 1.25s;
}

img.fadeInLeft.animated.iconEasy {
  animation-delay: 1.55s;
}

.iconEmployeeApp img,
.iconCustomerApp img {
  max-width: 165px;
}

.heading-block {
  margin-bottom: 20px;
}

.sectionGreen {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a5e091+0,65c651+100 */
  background: #a5e091;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse farthest-corner, #a5e091 0%, #65c651 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, #a5e091 0%, #65c651 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #a5e091 0%, #65c651 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a5e091', endColorstr='#65c651', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.sectionYellow {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4e8a8+0,f8df60+100 */
  background: #f4e8a8;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse farthest-corner, #f4e8a8 0%, #f8df60 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, #f4e8a8 0%, #f8df60 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #f4e8a8 0%, #f8df60 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4e8a8', endColorstr='#f8df60', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.sectionGreen,
.sectionYellow {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sectionBenefits {
  margin-top: 20px;
}

.sectionBenefits p {
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  border-bottom: 1px solid rgba( 0, 0, 0, .25);
  font-weight: 400;
  margin-bottom: 0 !important;
}

.col-padding {
  padding: 0;
}

.sectionBenefits p:last-of-type {
  border-bottom: 0;
}

.thumbnailVideo {
  border-radius: 3px;
  box-shadow: 2px 3px 20px 0px rgba(0, 0, 0, 0.35);
  width: 100%;
  height: auto;
}

body>video {
  width: 100%;
}

.testi-image,
.testi-image a,
.testi-image img,
.testi-image i {
  display: block;
  width: 64px;
  height: 64px;
}

.testi-content p,
.testi-meta,
.testi-meta span {
  font-weight: normal;
  margin-right: 5px;
}

.testimonialHr {
  margin: 1.5em;
  border-bottom: 1px solid #d2d2d5;
}

.sectionInstructions {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#bcbcc3+0,9898a0+100 */
  background: #bcbcc3;
  /* Old browsers */
  background: -moz-linear-gradient(top, #bcbcc3 0%, #9898a0 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #bcbcc3 0%, #9898a0 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #bcbcc3 0%, #9898a0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcbcc3', endColorstr='#9898a0', GradientType=0);
  /* IE6-9 */
}

.instructions {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4e8a8+0,f8df60+100 */
  background: #f4e8a8;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse farthest-corner, #f4e8a8 0%, #f8df60 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, #f4e8a8 0%, #f8df60 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #f4e8a8 0%, #f8df60 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4e8a8', endColorstr='#f8df60', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  border-radius: 5px;
  padding: 3px;
  margin: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.35);
}

.instructions p {
  margin: 10px;
  height: 115px;
}

@media (max-width: 767px) and (min-width: 576px) {
  .instructions p {
    margin: 5px;
    height: 75px;
  }
}

@media (max-width: 575px) {
  .instructions p {
    margin: 5px;
    height: unset;
  }
}

.instructions img {
  width: 250px;
  height: 250px;
}

.stepNumber {
  padding-left: 10px;
  margin-bottom: 5px;
  font-size: 2em;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#85c176+0,65c651+100 */
  background: #85c176;
  /* Old browsers */
  background: -moz-linear-gradient(top, #85c176 0%, #65c651 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #85c176 0%, #65c651 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #85c176 0%, #65c651 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#85c176', endColorstr='#65c651', GradientType=0);
  /* IE6-9 */
  border-radius: 2px;
  font-weight: 600;
  color: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.8);
}

.sectionDivider.getStarted h1 {
  padding-top: 0;
}

.button-light {
  background-color: #4cb534;
  color: white;
  text-transform: none;
  font-weight: 400 !important;
  font-size: 1.5em !important;
}

.button-light:hover,
.button-light:active {
  background-color: #a2fc8d;
  color: #333;
}

/*--------- BUSINESS ICONS START */

.businessIcon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: black;
  padding-top: 25px;
  padding-bottom: 25px;
  padding: 8px;
  border-radius: 3px;
  background-color: #f1f2f5;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,d7dcea+100 */
  background: #ffffff;
  /* Old browsers */
  background: -moz-radial-gradient(center, ellipse farthest-corner, #ffffff 0%, #d7dcea 100%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse farthest-corner, #ffffff 0%, #d7dcea 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, #ffffff 0%, #d7dcea 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d7dcea', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  height: 250px;
}

#content h2.businessLabel,
#content h2.yellowGreenSections {
  font-size: 1.5em;
  color: #333;
}

.food,
.bars,
.hair,
.auto,
.specialty,
.retail {
  width: 125px;
  height: 125px;
  box-shadow: 2px 3px 20px 0px rgba(0, 0, 0, 0.35);
  border-radius: 25px;
}

@media (max-width: 767px) and (min-width: 576px) {
  #content h2.businessLabel {
    font-size: 1.2em;
  }
  .businessIcon {
    height: 230px;
  }
}

@media (max-width: 576px) {
  .food,
  .bars,
  .hair,
  .auto,
  .specialty,
  .retail {
    width: 100px;
    height: 100px;
  }
  #content h2.businessLabel {
    font-size: 1.1em;
  }
  .businessIcon {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 200px;
  }
}

.food {
  background-image: url("../images/icon-food.svg");
  background-color: #f17f3f;
  background-repeat: no-repeat;
  background-position: center;
}

.bars {
  background-image: url("../images/icon-bars.svg");
  background-color: #674ba9;
  background-repeat: no-repeat;
  background-position: center;
}

.hair {
  background-image: url("../images/icon-hair.svg");
  background-color: #44b9c7;
  background-repeat: no-repeat;
  background-position: center;
}

.auto {
  background-image: url("../images/icon-auto.svg");
  background-color: #3b86dc;
  background-repeat: no-repeat;
  background-position: center;
}

.specialty {
  background-image: url("../images/icon-specialty.svg");
  background-color: #e27197;
  background-repeat: no-repeat;
  background-position: center;
}

.retail {
  background-image: url("../images/icon-retail.svg");
  background-color: #4abf7b;
  background-repeat: no-repeat;
  background-position: center;
}

span h2.businessLabel {
  font-weight: 400;
}

/*--------- BUSINESS ICONS END */

/*--------- FOOTER START */

#footer.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #515a63;
  width: 100%;
  height: 100px;
}

#footer.footer p {
  color: white;
  font-weight: 400;
}

//CONTACT FORM
div.modal-header .close {
  color: white !important;
}

.contactForm {
  background-color: #1a1a1b !important;
}

label {
  text-transform: none;
  font-size: 1.5em;
  margin-bottom: 2px;
  margin-top: 10px;
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 0;
}

/*--------- Contact Redirect Start */

.redirectContactForm {
  display: flex;
  padding: 50px;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 15vh;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4e4e5a+0,3d3d4b+100 */
  background: #4e4e5a;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4e4e5a 0%, #3d3d4b 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4e4e5a 0%, #3d3d4b 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4e4e5a 0%, #3d3d4b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4e4e5a', endColorstr='#3d3d4b', GradientType=0);
  /* IE6-9 */
  color: #f3d95e !important;
}

.redirectLogo {
  width: 125px;
}

.redirectLogo {
  animation: logoIntro 1.5s;
}

@keyframes logoIntro {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
  }
}

.message {
  animation: messageIntro 1.25s ease;
}

@keyframes messageIntro {
  0% {
    opacity: 0;
    transform: translateY(-75px);
  }
  100% {
    opacity: 1;
  }
}

.redirectContactForm>.buttonContainer {
  animation: buttonIntro 1s;
}

@keyframes buttonIntro {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
  }
}

.redirectContactForm h2 {
  color: white;
  font-size: 2.5rem;
  letter-spacing: 2px;
  text-transform: none;
  font-weight: 400;
  line-height: 1.2;
}

.redirectContactForm p {
  color: white;
  font-size: 1.5em;
}

.redirectContactForm a {
  color: white;
}

.redirectContactForm button {
  background-color: #4cb534;
  color: white;
  width: 100%;
  text-transform: none;
  font-size: 1.5em;
  border: 0;
  border-radius: 2px;
  padding: 4px 10px;
}

/*--------- Contact Redirect End */