
.carousel .slide {
  background: white;
}

/* CAROUSEL CUSTOMIZATION START */
.thumbs-wrapper {
  display: none;
}

p.carousel-status{
  display: none;
}

.carousel .control-dots {
  display: none;
}

.carousel.carousel-slider .control-arrow {
  display: none;
}


/* CAROUSEL CUSTOMIZATION END */
